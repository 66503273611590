.SignupScreen {
    max-width: 300px;
    padding: 50px;
    margin-right: auto;
    margin-left: auto;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 15px;
}

.SignupScreen>form {
    display: grid;
    flex-direction: column;
}

.SignupScreen>form>h1 {
    margin-bottom: 25px;
}

.SignupScreen>form>input {
    outline-width: 0;
    height: 40px;
    margin-bottom: 14px;
    border-radius: 5px;
    border: none;
    padding: 5px 15px;
}

.SignupScreen>form>button {
    outline-width: 0;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    margin-bottom: 14px;
    border-radius: 5px;
    background-color: #009FFD;
    padding: 10px 15px;
    cursor: pointer;
    margin-top: 20px;
}

.accepttosbutton {
    outline-width: 0;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    margin-bottom: 14px;
    border-radius: 5px;
    background-color: #64c6ff;
    padding: 10px 15px;
    cursor: pointer;
    margin-top: 20px;
}

.SignupScreen>form>h5 {
    text-align: left;
    margin-top: 30px;
}

.SignupScreen__link:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media only screen and (max-width: 850px) {
    .SignupScreen {
        width: 90%;
        margin-left: -38px;
    }
}