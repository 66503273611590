.loginscreen_app {
    height: 85%;
    background-size: cover;
    background-image: url("../../images/3.webp");
    /*background-image: url("../../images/gradient.webp");*/

    display: flex;
    align-items: center;
    justify-content: center;
}

.loginscreen__logo {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 10px;
    top: 10px;
    z-index: 1000;
    object-fit: cover;
    border-radius: 25px;
}

.loginscreen__body {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    z-index: 1;
    color: white;
    /*left and right 0 to center the text then*/
    position: absolute;
    text-align: center;
}





.referralmaindiv {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;

}

.referralinnerdiv {
    width: 80%;
    height: 80%;
    background-color: #009FFD;
    border-radius: 30px;
    display: flex;
    align-items: center;
}

.referralfirstinnerdiv>h2 {
    color: white;
    text-align: center;
}

.referralfirstinnerdiv>h4 {
    color: white;
    text-align: center;
}

.referralfirstinnerdiv {
    padding-left: 15px;
    width: 50%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.referfriend__button {
    z-index: 1000;
    padding: 12px 30px;
    font-size: 1.2rem;
    color: #009FFD;
        background-color: white;
    font-weight: 600;
    border: none;
    cursor: pointer;
    border-radius: 10px;
}

.faq__button {
    z-index: 1000;
    padding: 8px 30px;
    font-size: 14px;
    color: white;
    background-color: gray;
    font-weight: 500;
    border: none;
    cursor: pointer;
    border-radius: 10px;
}

.loginscreen__body>h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.loginscreen__body>h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: 400;
}

.loginscreen__body>h3 {
    font-size: 1.3rem;
    margin-bottom: 20px;
    font-weight: 400;
}

.loginscreen__input>form>input {
    padding: 10px;
    outline-width: 0px;
    height: 30px;
    width: 30%;
    border: none;
    max-width: 600px;
}

.loginscreen__input {
    margin: 20px;
}




.footer_middle>a {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 12px;
    text-decoration: none;
    color: gray;
}

.login_input {
    border-radius: 10px;
    margin-right: 5px;
    max-width: 300px;
    text-align: center;
    font-size: 14;
    font-weight: bold;
}

.whatispremsimainmain {
    margin-top: 30px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.premsiexplanationp {
    color: black;
    width: 80%;
    font-size: 15px;
    line-height: 25px;
    font-weight: bold;
    text-align: center;
}

.whatispremsimain {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 30px;
}

.whatispremsidiv {
    width: 25%;
    height: 220px;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.whatispremsiimage {
    height: 75px;
    width: 75px;
    border-radius: 15px;
}

.whatispremsidiv>h2 {
    margin-top: 5px;
    margin-bottom: 5px;
    color: black;
}

.whatispremsidiv>p {
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 14px;
    text-align: center;
    color: black;
}

.explainappdiv_apppres {
    height: 850px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 80px;
    margin-top: 80px;
    margin-bottom: 25px;
}

.explainappdiv_apppres>h2 {
    color: black;
    margin-top: 10px;
    width: 80%;
    text-align: center;
}

.explainappinnerdivpres {
    height: 45%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.explainappthirddivpres {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.explainappthirdimgnormalpres {
    height: 300px;
    border-radius: 15px;
}

.explainappthirdimgpres {
    height: 300px;
    border-radius: 15px;
    border: 1px solid black;
}

.calcp{
width: 340px;
text-align: left;
font-weight: bold;
margin-bottom: 14px;
font-size: 15px;
}

.appbtns{
display: flex;
width: 50%;
align-items: center;
justify-content: space-around;
margin-bottom: 20px;
margin-top: 30px;
}


@media only screen and (max-width: 850px) {
    .loginscreen__body>h1 {
        font-size: 2.3rem;
        margin-bottom: 20px;
    }

    .appbtns{
        display: flex;
        width: 80%;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;
        margin-top: 30px;
        }
        

    .calcp{
        width: 280;
        text-align: center;
        font-weight: bold;
        margin-bottom: 14px;
        font-size: 17px;
        }

    .loginscreen__body>h2 {
        font-size: 1.7rem;
        margin-bottom: 20px;
        font-weight: 400;
    }

    .loginscreen__body>h3 {
        font-size: 1.3rem;
        margin-bottom: 20px;
        font-weight: 400;
    }

    .loginscreen__input>form>input {
        padding: 10px;
        outline-width: 0px;
        height: 30px;
        width: 70%;
        border: none;
        max-width: 600px;
    }

    .loginscreen__input {
        flex-direction: column;
        align-items: center;
    }

    .login_input {
        border-radius: 10px;
        margin-bottom: 20px;
        max-width: 300px;
        text-align: center;
        font-size: 14;
        font-weight: bold;
    }

   

    /*what is premsi -> first div*/
    .whatispremsimainmain {
        margin-top: 30px;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .premsiexplanationp {
        color: black;
        width: 80%;
        font-size: 13px;
        line-height: 20px;
        font-weight: bold;
        text-align: center;
    }

    .whatispremsimain {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .whatispremsidiv {
        width: 85%;
        height: 220px;
        padding: 10px;
        border-radius: 15px;
        border: 1px solid black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
    }

    .whatispremsiimage {
        height: 75px;
        width: 75px;
        border-radius: 15px;
    }

    .whatispremsidiv>h2 {
        margin-top: 5px;
        margin-bottom: 5px;
        color: black;
    }

    .whatispremsidiv>p {
        margin-top: 2px;
        margin-bottom: 2px;
        font-size: 14px;
        text-align: center;
        color: black;
    }

    /*for explain app for mobile*/

    .explainappdiv_apppres {
        height: 2600px;
        width: 100%;
        background-color: white;
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 40px;
    }

    .explainappdiv_apppres>h2 {
        color: black;
        margin-top: 10px;
        width: 80%;
        text-align: center;
    }

    .explainappinnerdivpres {
        height: 49%;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .explainappthirddivpres {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }

    .explainappthirdimgnormalpres {
        height: 250px;
        border-radius: 15px;
    }

    .explainappthirdimgpres {
        height: 250px;
        border-radius: 15px;
        border: 1px solid black;
    }



    /*for referral div*/
    .referralmaindiv {
        width: 100%;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0px;

    }

    .referralinnerdiv {
        width: 90%;
        height: 90%;
        background-color: #009FFD;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .referralfirstinnerdiv {
        width: 85%;
        margin-top: 10px;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    .referralfirstinnerdiv>h2 {
        color: white;
        text-align: center;
        margin-top: 5px;
    }

    .referralfirstinnerdiv>h4 {
        color: white;
        text-align: center;
    }

    .referfriend__button {
        z-index: 1000;
        padding: 12px 30px;
        font-size: 1.2rem;
        color: #009FFD;
        background-color: white;
        font-weight: 600;
        border: none;
        cursor: pointer;
        border-radius: 10px;
    }

}