.faqouter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;
}

.selectdiv {
    width: 90px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    color: white;
}

from here all new

h2,
h3,
p,
h4 {
    color: black;
}

.headerlegal {
    border-bottom: 1px solid white;
    margin-bottom: 20px;
}

.innerlegaldivfaq>h2 {
    margin-top: 20px;
    text-decoration: underline;
}

.question {
    margin-top: 20px;
    margin-bottom: 7px;
    font-weight: 800;
    font-size: 17px;
}

.answer {
    padding-left: 10px;
}

.outerlegaldiv {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    align-items: center;
    flex-direction: column;
}

.innerlegaldivfaq {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.heading_qa {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.faqsi {
    width: 80%;
    max-width: 600px;
    margin: 0;
    padding: 15px;
}

.faqsi .faq {
    margin: 15px;
    padding: 13px;
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.faqsi .faq .faq-question {
    position: relative;
    font-size: 15px;
    padding-right: 80px;
    font-weight: bold;
    transition: all 0.4s ease;
}

.faqsi .faq .faq-question::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;

    background-image: url('./arrow-down-mint.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    transition: all 0.4s ease-out;
}

.faqsi .faq .faq-answer {
    opacity: 0;
    font-size: 13px;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.4s ease-out;
}

.faqsi .faq.open .faq-question {
    margin-bottom: 15px;
}

.faqsi .faq.open .faq-question::after {
    transform: translateY(-50%) rotate(180deg);
}

.faqsi .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
}

@media only screen and (max-width: 850px) {
    .outerlegaldiv {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    .innerlegaldivfaq {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }

    p,
    h3 {
        text-align: center;
    }
}