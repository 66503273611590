.errorpage {
    height: 100vh;
    width: 100vw;
}

.loginscreen__body_error {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    z-index: 1;
    color: white;
    /*left and right 0 to center the text then*/
    position: absolute;
    text-align: center;
}

.loginscreen__body_error_loggedin {
    padding-top: 20px;
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    color: white;
    /*left and right 0 to center the text then*/
    text-align: center;
}

.loginscreen__body_error>h1 {
    font-size: 2.1rem;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.loginscreen__body_error>h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: 400;
}

.loginscreen__body_error>h4 {
    font-size: 1.3rem;
    margin-bottom: 20px;
    font-weight: 400;
}

.error_pic {
    object-fit: cover;
    width: 200px;
    height: 200px;
    cursor: pointer;
    border-radius: 30px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 850px) {
    .loginscreen__body_error_loggedin {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        z-index: 1;
        color: white;
        /*left and right 0 to center the text then*/
        text-align: center;
    }
}