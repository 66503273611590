.postscreen {
    color: white;
    width: 100%;
    height: 100vh;
}

.postscreen__body {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    max-width: 800px;
    min-height: 75%;
}

.postscreen__infopm {
    /*dadurch ist div postscreen__infopm alles in einer row aber wenn div da drin ist ist darin normal*/
    display: flex;
    flex-direction: column;
}

.avatarpostscreen {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    object-fit: cover;
    transition: transform 450ms;
}

.avatarpostscreen_nopic {
    width: 40px;
    height: 40px;
    border-radius: 30px;
    object-fit: cover;
    transition: transform 450ms;
}

.postscreen__details {
    color: white;
    flex: 1;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.useremail {
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 15px;
    background-color: rgba(128, 128, 128, 0.6);
    font-size: 15px;
    text-align: center;
}

.sharebtn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: white;
}


.usertopinfotext {
    font-size: 13px;
    width: 30%;
}

.postidvouter {
    margin-top: 20px;
    height: 40vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.postdivpm {
    height: 40vh;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgray;
    border-radius: 30px;
}

.postinfopm {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    background-color: rgba(128, 128, 128, 0.3);
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 15px;
    padding-left: 25px;
    padding-right: 25px;
}

.postinnerinfo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    text-align: center;
}

.premiumnotavailableparent {
    padding: 15px;
    border: 1px solid white;
    border-radius: 15px;
    margin-top: 30px;
}

.premiumnotavailable {
    width: 80%;
    background-color: #009FFD;
}

.usertopinfotext>p {
    text-align: center;
}

.userbioinfo {
    text-align: center;
    margin-top: 10px;
    background-color: rgba(128, 128, 128, 0.3);
    padding: 15px;
    font-size: 14px;
    border-radius: 15px;
}

.userProfilesocials {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.belowuserinfo {
    text-align: center;
    margin-top: 10px;
    background-color: #56c1ff;
    padding: 10px;
    color: white;
    font-size: 14px;
    border-radius: 15px;
}

.userPosts {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 5px;
    padding-right: 5px;
}

.belowpostsinfo {
    text-align: center;
    margin-top: 10px;
    background-color: #009FFD;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 60px;
    padding-right: 60px;
    font-size: 14px;
    border-radius: 15px;
    color: white;
}

.profilepicdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
}

.userprofilecounts {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(128, 128, 128, 0.3);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 15px;
}

.groupdescription {
    width: 100%;
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: space-around;
}

.amountfilesouter {
    width: 100%;
    display: flex;
    margin-top: 15px;
    align-items: center;
    justify-content: space-around;
}

.groupdescriptioninner {
    width: 70%;
    background-color: rgba(84, 84, 84, 0.3);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 15px;
}

.amountfilesinner {
    width: 20%;
    background-color: rgba(84, 84, 84, 0.3);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 15px;
}

.buypmbtn {
    width: 70%;
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #009FFD;
    border-radius: 15px;
    color: white;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.noemail {
    color: orange;
    width: 80%;
    margin-bottom: 10px;
    text-align: center;
}

.buypmbtnapp {
    width: 70%;
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #009FFD;
    border-radius: 15px;
    color: white;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;

}

.buypmbtnend {
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #56c1ff;
    border-radius: 15px;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}

.buypmbtnendpp {
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #56c1ff;
    border-radius: 15px;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
}

.buypmbtnendnotenoguhbalance {
    padding: 7px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: rgb(255, 83, 83);
    border-radius: 15px;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
}

.searchinputpp {
    width: 100%;
    height: 35px;
    outline: 0;
    display: none;
    font-size: 14px;
    background-color: red;
    border: 0;
    border-radius: 25px;
    padding-left: 20px;
    padding-right: 10px;
}

.searchinputpp::placeholder {
    color: black;
}

@media only screen and (max-width: 850px) {

    .postinteractioninfo {
        width: 50px;
    }

    .sharebtn {
        width: 50px;
    }

    .profilepicdiv {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
    }

    .postscreen__infopm {
        /*dadurch ist div postscreen__infopm alles in einer row aber wenn div da drin ist ist darin normal*/
        display: flex;
        flex-direction: column;
    }

    .groupdescription {
        width: 100%;
        display: flex;
        margin-top: 10px;
        align-items: center;
        justify-content: space-around;
    }

    .groupdescriptioninner {
        width: 65%;
        background-color: rgba(84, 84, 84, 0.3);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 15px;
    }

    .amountfilesouter {
        width: 100%;
        display: flex;
        margin-top: 15px;
        align-items: center;
        justify-content: space-around;
    }

    .amountfilesinner {
        width: 20%;
        background-color: rgba(84, 84, 84, 0.3);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 15px;
    }

    .postscreen__details {
        color: white;
        flex: 1;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .postscreen__body {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;
        max-width: 800px;
        min-height: 75%;
    }

    .postinnerinfo {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 80%;
        text-align: center;
    }
}