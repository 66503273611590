.row {
    color: white;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.row__posters {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    width: 100%;
    margin-top: 20px;
}

.row__posters::-webkit-scrollbar {
    display: none;
}

/*mit overflowy hidden unf overflowx scroll im div dass den map umgibt kann man horizontale scroll machen*/

.row__poster {
    cursor: pointer;
    max-height: 120px;
    object-fit: cover;
    margin-right: 15px;
    transition: transform 450ms;
}

.row__poster:hover {
    transform: scale(1.1);
    opacity: 1;
}

.row__posterLarge {
    cursor: pointer;
    max-height: 120px;
    margin-right: 30px;
}

.no_prem_subsc {
    min-width: 200px;
    max-width: 200px;
    height: 100px;
    border: 1px solid #009FFD;
    border-radius: 20px;
    padding: 20px;
}

.learnmorebtn_rows {
    border: 0;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: #009FFD;
    color: white;
}

.seemoreinapp {
    min-width: 200px;
    height: 80px;
    border: 1px solid #009FFD;
    border-radius: 20px;
    padding: 20px;
}

.downloadnowbtn_rows {
    border: 0;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: #009FFD;
    color: white;
}