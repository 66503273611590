.profilescreen {
    color: white;
    width: 100%;
    height: 100vh;
}

.profilescreen__body {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    max-width: 800px;
    min-height: 75%;
}

.profiletop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.avatar {
    width: 120px;
    height: 120px;
    object-fit: cover;
    transition: transform 450ms;
}

.avatar_nopic {
    width: 60px;
    height: 60px;
    object-fit: cover;
    transition: transform 450ms;
}

.profilescreen__body>h1 {
    font-size: 60px;
    font-weight: 400;
    border-bottom: 1px solid #282c2d;
}

.profilescreen__info {
    /*dadurch ist div profilescreen__info alles in einer row aber wenn div da drin ist ist darin normal*/
    display: flex;
}

.profilescreen__info>img {
    height: 100px;
}

.profilescreen__details {
    color: white;
    flex: 1;
    margin-left: 25px;
}

.profilescreen__signout {
    padding: 10px 20px;
    font-size: 1rem;
    margin-top: 5%;
    width: 100%;
    border-radius: 10px;
    color: white;
    opacity: 0.7;
    background-color: rgb(98, 98, 98);
    font-weight: 600;
    border: none;
    cursor: pointer;
}

.profilescreen__plans {
    margin-top: 20px;
}

.profilescreen__plans>h3 {
    padding-bottom: 10px;
    border-bottom: 1px solid #282c2d;
}

.fundingrowon {
    border: 1px solid white;
    border-radius: 15px;
    padding: 5px;
}

.inrow {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #282c2d;
}


.profileemail {
    padding: 15px;
    padding-left: 20px;
    font-size: 15px;
}

.changeinfoinapp {
    background-color: #009FFD;
    /* background-color: rgba(60, 60, 255, 0.5);*/
    padding: 15px;
    padding-left: 20px;
    font-size: 15px;
    color: white;
    text-decoration: underline;
}

/* for paypal connect popup */

.paypalconnect_popup {
    width: 50%;
    height: auto;
    background-color: rgb(16, 13, 13);
    border-radius: 25px;
    text-align: center;
    padding: 20px;
    border: 1px solid white;
}

.paypalconnect_popup>h4 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.paypalconnect_popup>h2 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.editprofileheading {
    font-size: 50px;
    margin-left: 30px;
    text-decoration: underline;
    cursor: pointer;
}

.emaildiv {
    display: flex;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.3);
}

.pptext {
    margin-top: 7px;
    font-size: 12px;
    color: black;
}

@media only screen and (max-width: 850px) {

    .changeinfoinapp {
        background-color: #009FFD;
        /* background-color: rgba(60, 60, 255, 0.5);*/
        padding: 15px;
        padding-left: 20px;
        text-align: center;
        font-size: 15px;
        color: white;
        text-decoration: underline;
    }

    .profiletop {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .profilescreen__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        max-width: 800px;
        min-height: 75%;
    }

    .pptext {
        margin-bottom: 10px;
    }

    .copyrefer,
    .senstextbtn,
    .fundingbtns {
        margin-top: 15px;
    }

    .inrow {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid #282c2d;
    }

    .emaildiv {
        display: flex;
        align-items: center;
        background-color: rgba(128, 128, 128, 0.3);
        flex-direction: column;
        padding-bottom: 10px;
    }

    .editprofileheading {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .profilescreen__info {
        /*dadurch ist div profilescreen__info alles in einer row aber wenn div da drin ist ist darin normal*/
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .profilescreen__info>img {
        height: 100px;
        width: 100px;
        border-radius: 50px;
    }

    .avatar {
        width: 120px;
        height: 120px;
        object-fit: cover;
        transition: transform 450ms;
        margin-bottom: 10px;
    }

    .avatar_nopic {
        width: 60px;
        height: 60px;
        object-fit: cover;
        transition: transform 450ms;
        margin-bottom: 10px;
    }

    .profilescreen__details {
        color: white;
        flex: 1;
        margin-left: 0px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .paypalconnect_popup {
        width: 80%;
        height: auto;
        background-color: black;
        border-radius: 25px;
        text-align: center;
        padding: 20px;
        border: 1px solid white;
    }
}