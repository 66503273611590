.postscreen {
    color: white;
    width: 100%;
    height: 100vh;
}

.postscreen__body {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    max-width: 800px;
    min-height: 75%;
}

.postscreen__info {
    /*dadurch ist div postscreen__info alles in einer row aber wenn div da drin ist ist darin normal*/
    display: flex;
    justify-content: space-around;
}

.avatarpostscreen {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    object-fit: cover;
    transition: transform 450ms;
}

.avatarpostscreen_nopic {
    width: 40px;
    height: 40px;
    border-radius: 30px;
    object-fit: cover;
    transition: transform 450ms;
}

.postscreen__details {
    color: white;
    flex: 1;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.useremail {
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 15px;
    background-color: rgba(128, 128, 128, 0.6);
    font-size: 15px;
    text-align: center;
}

.sharebtn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: white;
}


.usertopinfotext {
    font-size: 13px;
    width: 30%;
}

.postdiv {
    margin-top: 20px;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:"hidden",
}

.postinfo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 10px;
    background-color: rgba(128, 128, 128, 0.3);
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 15px;
}

.postinnerinfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    text-align: center;
}

.premiumnotavailableparent {
    padding: 15px;
    border: 1px solid white;
    border-radius: 15px;
    margin-top: 30px;
}

.premiumnotavailable {
    width: 80%;
    background-color: #009FFD;
}

.usertopinfotext>p {
    text-align: center;
}

.userbioinfo {
    text-align: center;
    margin-top: 10px;
    background-color: rgba(128, 128, 128, 0.3);
    padding: 15px;
    font-size: 14px;
    border-radius: 15px;
}

.userProfilesocials {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.belowuserinfo {
    text-align: center;
    margin-top: 10px;
    background-color: #009FFD;
    padding: 15px;
    color:white;
    font-size: 14px;
    border-radius: 15px;
}

.userPosts {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 5px;
    padding-right: 5px;
}

.belowpostsinfo {
    text-align: center;
    margin-top: 10px;
    background-color: #009FFD;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 60px;
    padding-right: 60px;
    font-size: 14px;
    border-radius: 15px;
    color: white;
}

.profilepicdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
}

.userprofilecounts {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(128, 128, 128, 0.3);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 15px;
}

@media only screen and (max-width: 850px) {

    .postinteractioninfo {
        width: 50px;
    }

    .sharebtn {
        width: 50px;
    }

    .profilepicdiv {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
    }

    .postscreen__info {
        /*dadurch ist div postscreen__info alles in einer row aber wenn div da drin ist ist darin normal*/
        display: flex;
        flex-direction: column;
    }

    .userprofilecounts {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(128, 128, 128, 0.3);
        margin-top: 10px;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 15px;
    }

    .postscreen__details {
        color: white;
        flex: 1;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .postscreen__body {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;
        max-width: 800px;
        min-height: 75%;
    }

    .postinnerinfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        text-align: center;
    }
}