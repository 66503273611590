h2,
h3,
p,
h4 {
    color: black;
}

.headerlegal {
    border-bottom: 1px solid white;
    margin-bottom: 20px;
}

.innerlegaldiv>p,
h3 {
    margin-top: 20px;
}

.outerlegaldiv {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.innerlegaldiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
}

@media only screen and (max-width: 850px) {
    .outerlegaldiv {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    .innerlegaldiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }

    p,
    h3 {
        text-align: center;
    }
}