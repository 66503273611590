.loginscreen {
    height: 100%;
    background-size: cover;
    background-image: url("../../images/3.webp");
    /*background-image: url("../../images/gradient.webp");*/

    /*background-image: url("../../images/Premsioriginal.gif");*/

    display: flex;
    align-items: center;
    justify-content: center;
}

.appstoreouter {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.appstoreinner {
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: 10px;
}


/*background-image: url("../../images/Premsioriginal.gif");*/

.loginscreen__logo {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 10px;
    top: 10px;
    z-index: 1000;
    object-fit: cover;
    border-radius: 25px;
}

.loginscreen__body {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    z-index: 1;
    color: white;
    /*left and right 0 to center the text then*/
    position: absolute;
    text-align: center;
}

.loginscreen__button {
    position: absolute;
    z-index: 1000;
    right: 20px;
    top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #009ffd;
    background-color: white;
    font-weight: 650;
    border: 1px solid white;
    cursor: pointer;
    border-radius: 10px;
}

.loginnormal__button {
    z-index: 1000;
    padding: 8px 20px;
    font-size: 1rem;
    color: white;
    background-color: #009ffd;
    font-weight: 600;
    border: none;
    cursor: pointer;
    border-radius: 10px;
}


.referralmaindiv {
    width: 100%;
    height: 350px;
    margin-top: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.referralinnerdiv {
    width: 80%;
    height: 80%;
    background-color: #009FFD;
    border-radius: 30px;
    display: flex;
    align-items: center;

}

.referralfirstinnerdiv>h2 {
    color: white;
    text-align: center;
}

.referralfirstinnerdiv>h4 {
    color: white;
    text-align: center;
}

.referralfirstinnerdiv {
    padding-left: 15px;
    width: 50%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.referfriend__button {
    z-index: 1000;
    padding: 12px 30px;
    font-size: 1.2rem;
    color: #009FFD;
        background-color: white;
    font-weight: 600;
    border: none;
    cursor: pointer;
    border-radius: 10px;
}

.faq__button {
    z-index: 1000;
    padding: 8px 30px;
    font-size: 14px;
    color: white;
    background-color: gray;
    font-weight: 500;
    border: none;
    cursor: pointer;
    border-radius: 10px;
}

.loginscreen__body>h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.loginscreen__body>h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: 400;
}

.loginscreen__body>h3 {
    font-size: 1.3rem;
    margin-bottom: 20px;
    font-weight: 400;
}

.loginscreen__input>form>input {
    padding: 10px;
    outline-width: 0px;
    height: 30px;
    width: 30%;
    border: none;
    max-width: 600px;
}

.loginscreen__input {
    margin: 20px;
}

.loginscreen__getStarted {
    padding: 16px 15px;
    width: 250px;
    outline-width: 0;
    border-radius: 10px;
    margin-left: 0px;
    font-size: 1.1rem;
    color: white;
    background-color: #009FFD;
    border: none;
    font-weight: 700;
    cursor: pointer;
}


.footer_middle>a {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 12px;
    text-decoration: none;
    color: gray;
}

.login_input {
    border-radius: 10px;
    margin-right: 5px;
    max-width: 300px;
    text-align: center;
    font-size: 14;
    font-weight: bold;
}

.whatispremsimainmain {
    margin-top: 30px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.premsiexplanationp {
    color: black;
    width: 80%;
    font-size: 15px;
    line-height: 25px;
    font-weight: bold;
    text-align: center;
}

.whatispremsimainhome {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 30px;
}

.whatispremsidivhome {
    width: 15vw;
    height: 15vw;
    padding: 20px;
    border-radius: 15vw;
    background-color: #009FFD;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*align-items: center;
    justify-content: center;*/

.whatispremsiimage {
    height: 75px;
    width: 75px;
    border-radius: 15px;
}

.whatispremsidivhome>h2 {
    margin-top: 5px;
    margin-bottom: 5px;
    color: white;
}

.whatispremsidivhome>p {
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    color: white;
}

.everyuserdiv {
    margin-bottom: 70px;
    margin-top: 50px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.everyuserdivinner {
    padding-top: 10px;
    padding-bottom: 20px;
    width: 50%;
    background-color: #009FFD;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    z-index: 10;

}

.everyuserdivsecond {
    margin-bottom: 70px;
    margin-top: -100px;
    height: 150px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.everyuserdivinnersecond {
    padding-top: 50px;
    padding-bottom: 20px;
    width: 50%;
    background-color: #52c0ff;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    z-index: 1;
}

.everyuserdivinner>h3 {
    color: white;
    margin-bottom: 5px;
    width: 70%;
    text-align: center;
}

.everyuserdivinner>h4 {
    color: white;
    margin-bottom: 10px;
    width: 80%;
    text-align: center;
}

.explainappdiv {
    height: 430px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 80px;
}

.explainappdiv>h2 {
    color: black;
    margin-top: 10px;
    width: 80%;
    text-align: center;
}

.explainappdivwhatcreatorsachieved {
    height: 430px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 80px;
}

.explainappdivwhatcreatorsachieved>h2 {
    color: black;
    margin-top: 10px;
    width: 80%;
    text-align: center;
}

.explainappinnerdiv {
    margin-top: 20px;
    height: 90%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.explainappthirddiv {
    width: 25%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.explainappthirdimgnormal {
    height: 90%;
    border-radius: 15px;
}

.explainappthirdimg {
    height: 90%;
    border-radius: 15px;
    border: 1px solid black;
}

.featureouterdiv {
    width: 50%;
}

.featureinnerdiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.featurebtndiv {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.featurebtn {
    width: 100px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    background-color: #6fc9fd;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;
}

.priceexplanation {
    width: 80%;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
}


.featureexplanationmaindiv {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-bottom: 10px;
    margin-top: 30px;
}


.featureexplanationinnerdiv {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.featureexplanationhalfdiv {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.featureexplanationhalfdiv>h3 {
    width: 90%;
    text-align: center;
}

.featureexplanationhalfdiv>h4 {
    width: 70%;
    text-align: center;
    margin-top: 15px;
}

.creatorsachievedimage {
    height: 300px;
    border-radius: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
    border: 1px solid black;
}

@media only screen and (max-width: 850px) {

    .appstoreouter {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    
    .appstoreinner {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .loginscreen__button {
        position: relative;
        right: 0px;
        top: 0px;
        padding: 10px 20px;
        font-size: 1rem;
        color: #009ffd;
        background-color: white;
        font-weight: 650;
        border: 1px solid white;
        cursor: pointer;
        border-radius: 10px;
    }

    .creatorsachievedimage {
        height: 180px;
        border-radius: 30px;
        margin-top: 25px;
        margin-bottom: 25px;
        border: 1px solid black;
    }

    .loginscreen__body>h1 {
        font-size: 2.3rem;
        margin-bottom: 20px;
    }

    .loginscreen__body>h2 {
        font-size: 1.7rem;
        margin-bottom: 20px;
        font-weight: 400;
    }

    .loginscreen__body>h3 {
        font-size: 1.3rem;
        margin-bottom: 20px;
        font-weight: 400;
    }

    .loginscreen__input>form>input {
        padding: 10px;
        outline-width: 0px;
        height: 30px;
        width: 70%;
        border: none;
        max-width: 600px;
    }

    .loginscreen__input {
        flex-direction: column;
        align-items: center;
    }

    .login_input {
        border-radius: 10px;
        margin-bottom: 20px;
        max-width: 300px;
        text-align: center;
        font-size: 14;
        font-weight: bold;
    }

    .loginscreen__getStarted {
        padding: 16px 20px;
        border-radius: 10px;
        margin-left: -3px;
        font-size: 0.9rem;
        color: white;
        background-color: #009FFD;
        border: none;
        font-weight: 600;
        cursor: pointer;
    }

    /*what is premsi -> first div*/
    .whatispremsimainmain {
        margin-top: 30px;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .premsiexplanationp {
        color: black;
        width: 80%;
        font-size: 13px;
        line-height: 20px;
        font-weight: bold;
        text-align: center;
    }

    .whatispremsimainhome {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .whatispremsidivhome {
        width: 200px;
        height: 200px;
        padding: 20px;
        border-radius: 60vw;
        background-color: #009FFD;
        color: white;
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        align-items: center;
        justify-content: center;
    }

    .whatispremsiimage {
        height: 75px;
        width: 75px;
        border-radius: 15px;
    }

    .whatispremsidivhome>h2 {
        margin-top: 5px;
        margin-bottom: 5px;
        color: white;
    }

    .whatispremsidivhome>p {
        margin-top: 2px;
        margin-bottom: 2px;
        font-size: 14px;
        text-align: center;
        color: white;
    }

    /*every user... txt*/
    .everyuserdiv {
        height: 200px;
        margin-top: 100px;
        margin-bottom: 25px;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 10;
    }

    .everyuserdivinner {
        width: 90%;
        background-color: #009FFD;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        z-index: 10;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    .everyuserdivsecond {
        height: auto;
        margin-top: 45px;
        margin-bottom: 35px;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .everyuserdivinnersecond {
        width: 90%;
        padding-top: 0px;
        background-color: #52c0ff;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        z-index: 1;

    }

    /*for explain app for mobile*/

    .explainappdiv {
        height: 1300px;
        width: 100%;
        background-color: white;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 40px;
    }

    .explainappdiv>h2 {
        color: black;
        margin-top: 10px;
        width: 80%;
        text-align: center;
    }

    .explainappdivwhatcreatorsachieved {
        height: 350px;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 80px;
    }

    .explainappdivwhatcreatorsachieved>h2 {
        color: black;
        margin-top: 10px;
        width: 80%;
        text-align: center;
    }

    .explainappinnerdiv {
        height: 95%;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .explainappthirddiv {
        width: 90%;
        height: 23%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .explainappthirdimgnormal {
        height: 90%;
        border-radius: 15px;
    }

    .explainappthirdimg {
        height: 90%;
        border-radius: 15px;
        border: 1px solid black;
    }



    /*for referral div*/
    .referralmaindiv {
        width: 100%;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0px;

    }

    .referralinnerdiv {
        width: 90%;
        height: 90%;
        background-color: #009FFD;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .referralfirstinnerdiv {
        width: 85%;
        margin-top: 10px;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    .referralfirstinnerdiv>h2 {
        color: white;
        text-align: center;
        margin-top: 5px;
    }

    .referralfirstinnerdiv>h4 {
        color: white;
        text-align: center;
    }

    .referfriend__button {
        z-index: 1000;
        padding: 12px 30px;
        font-size: 1.2rem;
        color: #009FFD;
        background-color: white;
        font-weight: 600;
        border: none;
        cursor: pointer;
        border-radius: 10px;
    }

    .featureouterdiv {
        width: 70%;
    }

    .featureinnerdiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .featurebtndiv {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }

    .priceexplanation {
        width: 90%;
        text-align: center;
        font-size: 14px;
        margin-top: 20px;
    }

    .featureexplanationmaindiv {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        margin-bottom: 10px;
        margin-top: 30px;
    }

    .featureexplanationinnerdiv {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .featureexplanationhalfdiv {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .featureexplanationhalfdiv>h3 {
        width: 100%;
        text-align: center;
    }

    .featureexplanationhalfdiv>h4 {
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }

    .featureexplanationimagebelow {
        margin-top: 25px;
    }
}

@media only screen and (max-width: 500px)  {

    .appstoreouter {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    
    .appstoreinner {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    /*Put your CSS here for 768px to 850px width devices(covers all width between 768px to 850px */
    .featureexplanationmaindiv {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        margin-bottom: 10px;
        margin-top: 30px;
    }

    .featureexplanationimagebelow {
        margin-top: 25px;
    }

    .everyuserdivsecond {
        height: auto;
        margin-top: 45px;
        margin-bottom: 35px;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;

    }

    .everyuserdivinnersecond {
        width: 90%;
        padding-top: 0px;
        background-color: #52c0ff;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        z-index: 1;

    }

    .everyuserdiv {
        height: 200px;
        margin-top: 100px;
        margin-bottom: 25px;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 10;
    }

    .everyuserdivinner {
        width: 90%;
        background-color: #009FFD;
        border-radius: 30px;
        display: flex;
        padding-top: 10px;
    padding-bottom: 20px;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        z-index: 10;
    }

   

}