.footer_whole_home {
    width: 100vw;
    height: 150px;
    position: relative;
    background-color: #56c1ff;
    /*vertically and horizontally center div in div*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 5px;
    padding-bottom: 10px;
}

.footer_middle_home {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.footer_middle_home>a {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 11px;
    text-decoration: none;
    color: white;
    font-weight: bold
}

.nav__logo_home {
    width: 50px;
    height: 50px;
    border-radius: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.bplogo {
    width: 70px;
    height: 70px;
    border-radius: 30px;
    margin-right: 10px;
    margin-left: 10px;
}


.socialsfooter {
    display: flex;
    width: 170px;
    /*pro social icon um 50px erweitern*/
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 850px) {

    .footer_whole_home {
        width: 100%;
        margin-top: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        height: 420px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        position: relative;
        background-color: #56c1ff;
        /*background-color: rgb(35, 35, 35);
        /*vertically and horizontally center div in div*/
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .socialsfooter {
        display: flex;
        width: 190px;
        align-items: center;
        margin-top: 10px;
        justify-content: space-between;
    }

    .footer_middle_home {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    .footer_middle_home>a {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 4px;
        margin-bottom: 4px;
        font-size: 11px;
        text-decoration: none;
        color: white;
        font-weight: bold
    }

}