.userscreen {
    color: white;
    width: 100%;
    height: 100vh;
}

.userscreen__body {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    max-width: 800px;
    min-height: 75%;
}

.socialicon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.userscreen__info {
    /*dadurch ist div userscreen__info alles in einer row aber wenn div da drin ist ist darin normal*/
    display: flex;
}

.userscreen__info>img {
    height: 120px;
}

.avatar_profilebanner {
    border-radius: 30px;
    width: 600px;
    height: 300px;
    object-fit: cover;
    transition: transform 450ms;
}

.avatar_profile {
    border-radius: 80px;
    margin-top: -50px;
    width: 80px;
    height: 80px;
    object-fit: cover;
    transition: transform 450ms;
}

.avatar_nopic_profile_div {
    margin-top: -40px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar_nopic_profile {
    border-radius: 60px;
    margin-top: -50px;
    width: 80px;
    height: 80px;
    object-fit: cover;
    transition: transform 450ms;
}

.userscreen__details {
    color: white;
    flex: 1;
    margin-left: 0px;
    height: 120px;
}

.userscreen__topuserinfo {
    height: 120px;
    background-color: rgba(128, 128, 128, 0.3);
    border-radius: 15px;
}

.useremail {
    padding: 10px;
    border-radius: 15px;
    background-color: rgba(128, 128, 128, 0.3);
    font-size: 15px;
    text-align: center;
}

.usertopinfotext {
    font-size: 13px;
    width: 30%;
}


.premiumavailableparent {
    padding: 10px;
    border: 1px solid white;
    border-radius: 15px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.premiumnotavailableparent {
    padding: 15px;
    border: 1px solid white;
    border-radius: 15px;
    margin-top: 10px;
}

.premiumnotavailable {
    width: 80%;
    background-color: #009FFD;
}

.usertopinfotext>p {
    text-align: center;
}

.userbioinfo {
    text-align: center;
    margin-top: 10px;
    background-color: rgba(128, 128, 128, 0.3);
    padding: 15px;
    font-size: 14px;
    border-radius: 15px;
}

.userProfilesocials {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.inneruserProfilesocials {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.belowuserinfo {
    text-align: center;
    margin-top: 10px;
    background-color: #009FFD;
    padding: 15px;
    color: white;
    font-size: 14px;
    border-radius: 15px;
}

.userPosts {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 5px;
    padding-right: 5px;
}

.belowpostsinfo {
    text-align: center;
    margin-top: 10px;
    background-color: #009FFD;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 60px;
    padding-right: 60px;
    color: white;
    font-size: 14px;
    border-radius: 15px;
}

.profbtn {
    font-size: 15px;
}

.groupitem {
    width: 70%;
    border: 1px solid black;
    border-radius: 20px;
    height: 100px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.pmitem {
    width: 70%;
    border: 1px solid black;
    cursor: pointer;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.viewgroupbtn {
    padding: 15px;
    border-radius: 15px;
    color: white;
    background-color: #009FFD;
}

.groupimg {
    width: 75px;
    border-radius: 15px;
}

.topbannerprofile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
}

@media only screen and (max-width: 580px) {
    .avatar_profilebanner {
        border-radius: 30px;
        width: 100%;
        height: 300px;
        object-fit: cover;
        transition: transform 450ms;
    }

    .groupitem {
        width: 100%;
        border: 1px solid black;
        border-radius: 20px;
        height: 200px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .pmitem {
        width: 100%;
        border: 1px solid black;
        border-radius: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .profbtn {
        font-size: 10px;
    }

    .userscreen__info {
        /*dadurch ist div userscreen__info alles in einer row aber wenn div da drin ist ist darin normal*/
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .userscreen__body {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        max-width: 800px;
        min-height: 75%;
    }

    .userscreen__details {
        color: white;
        flex: 1;
        margin-left: 0px;
        height: 120px;
        margin-top: 10px;
    }

    .inneruserProfilesocials {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
}

@media only screen and (min-device-width: 580px) and (max-width: 850px) {

    .avatar_profilebanner {
        border-radius: 30px;
        width: 80%;
        height: 300px;
        object-fit: cover;
        transition: transform 450ms;
    }

    .groupitem {
        width: 100%;
        border: 1px solid black;
        border-radius: 20px;
        height: 200px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .pmitem {
        width: 100%;
        border: 1px solid black;
        border-radius: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .profbtn {
        font-size: 10px;
    }

    .userscreen__info {
        /*dadurch ist div userscreen__info alles in einer row aber wenn div da drin ist ist darin normal*/
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .userscreen__body {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        max-width: 800px;
        min-height: 75%;
    }

    .userscreen__details {
        color: white;
        flex: 1;
        margin-left: 0px;
        height: 120px;
        margin-top: 10px;
    }

    .inneruserProfilesocials {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
}