.SignupScreenNotHome {
    max-width: 300px;
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 15px;
}

.SignupScreenNotHome>form {
    display: grid;
    flex-direction: column;
}

.SignupScreenNotHome>form>h1 {
    margin-bottom: 25px;
}

.SignupScreenNotHome>form>input {
    outline-width: 0;
    height: 40px;
    margin-bottom: 14px;
    border-radius: 10px;
    border: none;
    padding: 5px 15px;
    background-color: #d0eeff;
}

.SignupScreenNotHome>form>button {
    outline-width: 0;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    margin-bottom: 14px;
    border-radius: 5px;
    background-color: #009FFD;
    padding: 10px 15px;
    cursor: pointer;
    margin-top: 20px;
}

.accepttosbutton {
    outline-width: 0;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    text-align: center;
    margin-bottom: 14px;
    border-radius: 5px;
    background-color: #64c6ff;
    padding: 10px 15px;
    cursor: pointer;
    margin-top: 20px;
}

.SignupScreenNotHome>form>h5 {
    text-align: left;
    margin-top: 10px;
}

.SignupScreen__link:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media only screen and (max-width: 850px) {
    .SignupScreenNotHome {
        max-width: 300px;
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 50px;
        margin-right: 0;
        margin-left: 0;
        border-radius: 15px;
    }

  
}