.plansscreen__plan {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    opacity: 0.8;
}

.plansscreen__plan:hover {
    opacity: 1;
}

.plansscreen__plan>button {
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #009FFD;
    border: none;
    font-weight: 600;
    cursor: pointer;
    border-radius: 7px;
}
/*
.plansscreen__plan--disabled>button {
    background-color: white !important;
}*/

.subtext {
    color: red;
    font-size: 11px;
}

@media only screen and (max-width: 850px) {
    .plansscreen {
        margin-bottom: 5px;
    }

    .plansscreen__info {
        text-align: center;
        margin-bottom: 10px;
    }

    .plansscreen__plan {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 0px;
        opacity: 1;
    }

}