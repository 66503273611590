.searchusers_row {
    cursor: pointer;
    width: 100%;
    border: 0;
    height: 50px;
    /*background-color: rgba(128, 128, 128, 0.6);*/
    margin-bottom: 10px;
    border-radius: 20px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.innersearchdiv {
    display: flex;
    align-items: center;
    flex: 1;
}

.search_username {
    padding-left: 10px;
    color: black;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.search_avatar {
    object-fit: cover;
    pointer-events: none;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    cursor: pointer;
    margin-left: 3px;
}

.search_p {
    width: 60%;
    text-align: left;
    color: black;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    margin-left: 10px;
}

.searchusernumbers {
    margin-right: 20px;
    color: black;
}

.searchusernumbers>p {
    text-align: left;
}