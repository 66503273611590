.nav {
    position: fixed;
    top: 0;
    padding: 5px;
    width: 100%;
    z-index: 100;
    background-color: black;

    /* for inner elements that set up corerctly*/
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
}

.nav__logo {
    object-fit: cover;
    left: 20px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 30px;
}

.nav__avatar {
    position: absolute;
    object-fit: cover;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    cursor: pointer;
}

.nav__avatar_nopic {
    position: absolute;
    object-fit: cover;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    cursor: pointer;
}

.searchform {
    height: 30px;
    width: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    border-radius: 50px;
    transition: 1.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.searchinput {
    margin-right: 5px;
    width: 100%;
    height: 35px;
    outline: 0;
    display: none;
    font-size: 14px;
    border: 0;
    border-radius: 25px;
    padding-left: 20px;
    padding-right: 10px;
}

.searchinput::placeholder {
    color: black;
}

.searchform:hover {
    width: 300px;
    cursor: pointer;
}

.searchform:hover input {
    display: block;
}

.navbtns {
    color: black;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    margin-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 50px;
    background-color: white;
}

.navbtnslogin{
    color: black;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 50px;
    background-color: white;
}

.navbtncont {
    display: flex;
}

.searchform:focus {
    width: 300px;
    cursor: pointer;
}

.searchform:focus input {
    display: block;
}

/* from tutorial for hamburger menu */

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    background-color: #009FFD;
    color: white;
}

nav a {
    margin: 0 1rem;
    color: white;
    text-decoration: none;
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: white;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

header div,
nav {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 850px) {
    header .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    header nav {
        position: fixed;
        top: -200vh;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: #009FFD;
        transition: 1s;
        z-index: 200;
    }

    header .responsive_nav {
        transform: translateY(200vh);
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    .navbtns {
        font-size: 1.3rem;
    }

    .navbtnslogin{
        font-size: 1rem;
    }

    .nav__avatar {
        position: relative;
        object-fit: cover;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        cursor: pointer;
        margin-left: 35px;
    }

    .nav__avatar_nopic {
        position: relative;
        object-fit: cover;
        width: 40px;
        height: 40px;
        border-radius: 30px;
        cursor: pointer;
        margin-left: 35px;
    }
}