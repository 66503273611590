.searchscreen {
    color: white;
    width: 100%;
    height: 100vh;
}

.searchResultsDiv {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    max-width: 800px;
    min-height: 75%;
}

.searchform_searchscreen {
    height: 30px;
    width: 300px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.searchinput_searchscreen {
    margin-right: 5px;
    width: 300px;
    height: 40px;
    font-size: 14px;
    border: 0;
    border-radius: 25px;
    padding-left: 20px;
    padding-right: 10px;
}

.searchinput_searchscreen::placeholder {
    color: black;
}

.grouppmdiv {
    display: flex;
    margin-top: 15px;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    width: 50vw;
}

@media only screen and (max-width: 850px) {

    .searchResultsDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 800px;
        min-height: 75%;
    }

    .grouppmdiv {
        display: flex;
        margin-top: 15px;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        width: 100vw;
    }

    .searchedusersdiv {
        width: 100%;
    }
}