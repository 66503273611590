.banner {
    margin-top: 0px;
    height: 400px;
    background-size: contain;
    background-repeat: no-repeat;
    object-fit: fill;
    background-position: center center;
    display: flex;
    align-items: center;
    background-color: black;
}

.banner__contents {
    margin-left: 30px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner__button {
    cursor: pointer;
    color: white;
    outline: none;
    border: none;
    margin-top: 5px;
    font-weight: 700;
    border-radius: 10px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 2rem;
    margin-left: 2rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    background-color: #009FFD;
    z-index: 0;
}

.banner__description {
    width: 45rem;
    font-size: 1.1rem;
    max-width: 360px;
    /* dass text nur eine linie */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    text-align: start;
}

.banner__button:hover {
    color: black;
    background-color: aliceblue;
    transition: all 0.3s;
}

@media only screen and (max-width: 850px) {
    .banner {
        margin-top: 0px;
        height: 300px;
        background-size: contain;
        object-fit: fill;
        background-position: center center;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .banner__contents {
        height: 200px;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .banner__title {
        font-size: 3rem;
        font-weight: 800;
    }

    .banner__button {
        cursor: pointer;
        color: white;
        outline: none;
        border: none;
        margin-top: 15px;
        font-weight: 700;
        border-radius: 10px;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-right: 0rem;
        margin-left: 0rem;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        background-color: #009FFD;
        z-index: 0;
    }

    .banner__description {
        width: 45rem;
        font-size: 0.8rem;
        margin-top: 10px;
        max-width: 200px;
        /* dass text nur eine linie */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        text-align: center;
    }
}